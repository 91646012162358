<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />

    <CHeaderBrand class="mx-auto d-lg-none site-logo" to="/dashboard">
      <!-- CIcon name="logo" height="48" alt="Logo"/ -->
      <img
        src="@/assets/images/liberty-resort-black.png"
        height="48"
        alt="LibertyResort"
      />
    </CHeaderBrand>
    <!-- delete this list  -->
    <!-- <CBadge @click="$router.push('area-master')">area master</CBadge> -->
    <!-- delete this list above  -->

    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3" v-if="isAdminRole">
        <CButton
          color="info"
          square
          block
          size="sm"
          @click="onAdminButtonClicked()"
        >{{ $t("header.switchToSiteAdministrator") }}
      </CButton>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3" v-if="isStaffRole">
        <CButton
            color="info"
            square
            block
            size="sm"
            @click="onStaffButtonClicked()"
          >{{ $t("header.switchToStaff") }}
        </CButton>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3" v-if="isOperatorRole">
         <CButton
          color="info"
          square
          block
          size="sm"
          @click="onOperatorButtonClicked()"
        >{{ $t("header.switchToOperator") }}
      </CButton>
      </CHeaderNavItem>
    </CHeaderNav>

    <div class="lg_section">
      <span class="mx-2 current_fg" @click="openLG()"
        ><CIcon :name="localFlag" width="30" class="m-auto" /><b
          class="icon-down"
          >🢓</b
        ></span
      >
      <transition name="fade">
        <ul class="fg_list" v-if="openFgList">
          <li>
            <span @click="$store.commit('clickLgJp'); openFgList=false"
              ><CIcon name="cif-jp" width="30" class="m-auto" />日本語</span
            >
          </li>
          <li>
            <span @click="$store.commit('clickLgEn'); openFgList=false"
              ><CIcon name="cif-us" width="30" class="m-auto" />English</span
            >
          </li>
          <li>
            <span @click="$store.commit('clickLgZh'); openFgList=false"
              ><CIcon name="cif-cn" width="30" class="m-auto" />中文</span
            >
          </li>
        </ul>        
      </transition>
    </div>

    <CSubheader class="px-3">
      <BreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import BreadcrumbRouter from '@/components/BreadcrumbRouter.vue';


const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
  OPERATOR: "yado_operator",
  MEMBER: "yado_member",
};

export default {
  name: "TheHeader",
  components: {
    BreadcrumbRouter
  },

  data() {
    return {
      user: { cardId: 0, address: {}, points: {} },
      fullName: "",
      loading: false,
      openFgList: false,
      langs: ["ja", "en", "zh"],
      isAdminRole:false,
      isStaffRole:false,
      isOperatorRole:false,

    };
  },
  created() {
    this.buttonSwitchHandler();
  },

  methods: {

    // #region event

    openLG() {
      this.openFgList = !this.openFgList;
    },

    /**スタッフへ戻るボタン押下 */
    onStaffButtonClicked(){
      this.moveToStaffPage();
    },

    /**オペレーター画面へ戻るボタン押下 */
    onOperatorButtonClicked(){
      this.moveToDashboardPage();
    },


    /**サイト管理者画面へ戻るボタン押下 */
    onAdminButtonClicked(){
      this.moveToAdminPage();
    },


    // #endregion event

    // #region method

    /**ボタンの表示切り替え */
    buttonSwitchHandler(){
     
      const roles = this.$keycloak.realmAccess.roles;
      if (roles.includes(ROLE.ADMIN)){
        this.isAdminRole=true;
      }
      else if(roles.includes(ROLE.STAFF)){
        this.isStaffRole=true;
      }else if (roles.includes(ROLE.OPERATOR)){
        this.isOperatorRole=true;
      }else{
        
      // メンバーが含まれていない時表示しない
      return;
      }
    },

    moveToStaffPage() {
      this.$store.commit('setUid',"");

      console.log(this.$store.state.uniqueId);
      this.$router.push({
        path: "/staff/dashboard"
      });
    },

    moveToDashboardPage() {
      this.$store.commit('setUid',"");
      console.log(this.$store.state.uniqueId);
      this.$router.push({
        path: "/operator/dashboard"
      });
    },
    moveToAdminPage() {
      this.$store.commit('setUid',"");
      console.log(this.$store.state.uniqueId);
      this.$router.push({
        path: "/admin/dashboard"
      });
    },

    // #endregion method

  },

  computed: {
    currentLg() {
      return this.$store.state.langsv;
    },
    loginRole() {
      // オペレーターか管理者であればロール名を返す
      const roles = this.$keycloak.realmAccess.roles;
      let loginRole = "様";
      if (roles.includes(ROLE.ADMIN)) {
        loginRole = " (管理者)";
      } else if (roles.includes(ROLE.OPERATOR)) {
        loginRole = " (オペレータ)";
      }
      return loginRole;
    },
    localFlag() {
      var currentLg;
      this.$i18n.locale == "ja"
        ? (currentLg = "cif-jp")
        : this.$i18n.locale == "en"
        ? (currentLg = "cif-us")
        : (currentLg = "cif-cn");
      return currentLg;
    },
  },
};
</script>
<style lang="scss">
.lg_section {
  margin: 10px 40px auto auto;
  position: relative;
  cursor: pointer;
  .icon-down {
    font-size: 1.8em;
    right: -11px;
    top: 12px;
    position: absolute;
    // bottom: 10px;
  }
  .current_fg {
    padding: 2px 4px 4px;
  }
  svg {
    border: 1px solid #cccccc;
  }
  .fg_list {
    position: absolute;
    right: 10px;
    top: 30px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    padding: 0 0 5px 0;
    margin: 0;
    background: #ffffff;

    li {
      list-style: none;
      min-width: 120px;
      margin: 5px 0;
      clear: both;
      span {
        float: left;
        margin: 0 5px;
        svg {
          margin-right: 10px !important;
          display: inline-block;
        }
      }
    }
  }
}
</style>
