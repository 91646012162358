<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand
      class="d-md-down-none"
      to="/dashboard"
    >
      <strong>{{ $t("nav.title") }}</strong>

    </CSidebarBrand>
    <CRenderFunction
      flat
      :content-to-render="navDatas"
    />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import config from '@/config'
const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
  OPERATOR: "yado_operator",
  MEMBER: "liberty_member",
};

export default {
  name: "TheSidebar",
  data() {
    return {

      /** アカウントが使用可能なルート情報 */
      routerData: [],

      /** 選択中ロール */
      selectedRole: "",

    };
  },

  created() {

    // ルート情報設定用に、選択中ロールに初期ロールを設定する
    this.selectedRole = this.initialRole;

    this.updateRouterData(this.selectedRole);
    
  },

  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },

    navDatas() {
      // 選択中のロールでルート情報更新
      this.updateRouterData(this.selectedRole);

      const menuDataStructure = [
        {
          _name: "CSidebarNav",
          _children: this.routerData,
        },
      ];

      return menuDataStructure;
    },

    // /** 表示用の初期ロールを取得 */
    initialRole() {
      // アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;
      // console.log(roles)

      // 優先度に応じたロールを返す
      if (roles.includes(ROLE.ADMIN)) return ROLE.ADMIN;
      if (roles.includes(ROLE.STAFF)) return ROLE.STAFF;
      if (roles.includes(ROLE.OPERATOR)) return ROLE.OPERATOR;

      return ROLE.MEMBER


    },


  },

  methods: {

    /** ルート情報更新 */
    updateRouterData(selectedRole) {
      // 全てのルート情報を設定
      console.log(selectedRole)
      // NOTE: i18n対応のためここで設定すること
      const allRouterDatas = [

        //#region ポータル
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Portal")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Dashboard"),
          to: "/member/dashboard",
          icon: "cil-speedometer",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 1,
        },
        //#endregion ポータル

        //#region 予約
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Reservation")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 2,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.ReservationHistory"),
          to: "/member/reservation-history",
          icon: "cil-history",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 2,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Waiting"),
          to: "/member/waiting",
          icon: "cil-bell-exclamation",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 2,
        },
        //#endregion 予約

        //#region クーポン
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Coupon")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 99,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Coupon"),
          to: "/member/coupon",
          icon: "cil-tags",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 99,
        },
        //#endregion クーポン

        //#region 会員
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.MemberInfo")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Profile"),
          to: "/member/profile",
          icon: "cil-user",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.EmailNewsletter"),
          to: "/member/mail-magazine",
          icon: "cil-envelope-closed",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 1,
        },
        //#endregion 会員

        //#region ログアウト
        {
          _name: "CSidebarNavTitle",
          _children: [this.$t("routerName.Logout")],
          _class: "parent_tab",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 1,
        },
        {
          _name: "CSidebarNavItem",
          name: this.$t("routerName.Logout"),
          to: "/logout",
          icon: "cil-room",
          viewRole: [ROLE.ADMIN, ROLE.STAFF, ROLE.OPERATOR, ROLE.MEMBER],
          version: 1,
        },
        //#endregion ログアウト
      ];

      // アカウントが所属するロールが使用できるルート情報に更新する
      this.routerData = allRouterDatas.filter((e) =>
        e.version <= config.VERSION && e.viewRole.some((v) => v == selectedRole)
      );

    },

  },
};
</script>